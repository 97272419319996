import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colorsGlobal } from "./_global";

const IFrameFb = ({ id }) => {
  const [isLoading, setLoading] = useState(true);
  const handleLoadedIFrame = () => {
    setLoading(false);
  };
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
        background: "rgba(204, 166, 255, 0.1)",
      }}
    >
      {isLoading && <Spinner />}
        <div id="video_div"
             className="fb-video"
             onLoad={handleLoadedIFrame}
             data-href={`https://www.facebook.com/facebook/videos/${id}/`}
             data-width="500"
             data-allowFullScreen="false"
        >
        </div>
    </div>
  );
};

const IFrameStyled = styled.iframe`
  height: 100%;
  width: 100%;
`;

const Spinner = styled.div`
  @keyframes rotate-spinner {
    100% {
      transform: rotate(360deg);
    }
  }
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  background: ${colorsGlobal.primary};
  border-top-color: white;
  opacity: 1;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 250ms;
  animation: rotate-spinner 1s linear;
  animation-iteration-count: infinite;
`;

export default IFrameFb;
