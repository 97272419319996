import React, { useCallback, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from "../../components/Layout/Layout";
import wojnaIdeiLogo from "../../img/wojnaIdeiLogo.svg";
import szymonMowiLogo from "../../img/szymonMowiLogo.svg";
import { mediaQuery } from "../../components/_breakpoints";
import { colorsGlobal, socialButtonsColors } from "../../components/_global";
import IFrame from "../../components/IFrame";
import ReactSlick from "react-slick";

import * as _ from "lodash";
import SocialButton from "../../components/ActionButtons/SocialButton";
import { graphql } from "gatsby";
import IFrameFb from "../../components/IFrameFb";

const perPage = 10;

const reactSlickSettings = (slidesToShow) => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesToShow < 5 ? slidesToShow : 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: slidesToShow < 4 ? slidesToShow : 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: slidesToShow < 3 ? slidesToShow : 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

export default ({ data }) => {
  const [channel, setChannel] = useState("wojna-idei");

  const [serviceChannel, setServiceChannel] = useState("youtube");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const categories =
    data.allMarkdownRemark.edges.find(
      (el) => el.node.frontmatter.channelName === channel
    ) || null;

  const sortedDataWI = data.wojnaIdeiYt.data.items.sort(function (a, b) {
    return new Date(b.snippet.publishTime) - new Date(a.snippet.publishTime);
  });

  const sortedDataSM = data.szymonMowiYt.data.items.sort(function (a, b) {
    return new Date(b.snippet.publishTime) - new Date(a.snippet.publishTime);
  });

  const ytFilms = useMemo(
    () =>
      (channel === "wojna-idei" ? sortedDataWI : sortedDataSM).slice(
        0,
        perPage * page
      ),
    [channel, page]
  );

  const fbFilms = data.wojnaIdeiFb.data.data;

  const [searchData, setSearchData] = useState("");
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    setPage(1);
    if (searchData === "") {
      setFilteredData([]);
      return;
    }
    if (channel === "wojna-idei") {
      setFilteredData(
        data.wojnaIdeiYt.data.items.filter((el) =>
          el.snippet.title.toLowerCase().includes(searchData)
        )
      );
    }
    if (channel === "szymon-mowi") {
      setFilteredData(
        data.szymonMowiYt.data.items.filter((el) =>
          el.snippet.description.includes(searchData)
        )
      );
    }
  }, [searchData, channel]);

  useEffect(() => {
    if (
      !!document &&
      !!document.location &&
      !!document.location.search &&
      document.location.search.includes("channel")
    ) {
      setChannel(document.location.search.split("=")[1]);
    }
  }, []);

  const handleSearchData = (data) => {
    setSearchData(data);
  };

  const debouncedSearchDataHandler = useCallback(
    _.debounce((data) => handleSearchData(data), 1000),
    []
  );

  const handleSwitcherClick = (channel) => {
    setChannel(channel);
    setPage(1);
  };

  const handleSearchInputChange = (e) => {
    setInputVal(e.target.value.toLowerCase());
    debouncedSearchDataHandler(e.target.value.toLowerCase());
  };

  const handleServiceSwitch = (data) => {
    if (data === "cda") {
      const pathsMap = {
        "szymon-mowi": "SzymonMowi",
        "wojna-idei": "WojnaIdei",
      };
      window.open(`https://www.cda.pl/${pathsMap[channel]}/vfilm`, "_blank");
    }
    if (data === "facebook") {
      window.open(`https://www.facebook.com/wojnaidei/videos/`, "_blank");
    }
    if (data !== "facebook" && data !== "cda") setServiceChannel(data);
  };

  return (
    <Layout>
      <Section>
        <NavTools>
          <SearchModule>
            <ChannelSwitcherWrapper>
              <SwitcherTitle>Wybierz kanał:</SwitcherTitle>
              <FirstLogo
                onClick={() => handleSwitcherClick("wojna-idei")}
                src={wojnaIdeiLogo}
                isFirstLogo={channel === "wojna-idei"}
              />
              <SecondLogo
                onClick={() => handleSwitcherClick("szymon-mowi")}
                src={szymonMowiLogo}
                isFirstLogo={channel === "wojna-idei"}
              />
            </ChannelSwitcherWrapper>
            {serviceChannel === "youtube" && (
              <SearchComponent>
                <SearchInput
                  placeholder="Szukaj filmów ..."
                  value={inputVal}
                  onChange={handleSearchInputChange}
                />
              </SearchComponent>
            )}
          </SearchModule>

          <SocialMediaSwitcher>
            <SocialButtonStyled
              name="youtube"
              isActive={serviceChannel === "youtube"}
              handleClick={() => handleServiceSwitch("youtube")}
            />
            <SocialButtonStyled
              name="facebook"
              isActive={serviceChannel === "facebook"}
              handleClick={() => handleServiceSwitch("facebook")}
            />
            <SocialButtonStyled
              name="cda"
              handleClick={() => handleServiceSwitch("cda")}
            />
          </SocialMediaSwitcher>
        </NavTools>
        {categories && (
          <CategoriesSlider>
            <CategoriesSliderTitle>Popularne kategorie:</CategoriesSliderTitle>
            <ReactSlickStyled {...reactSlickSettings(categories.node.frontmatter.films.length)}>
              {categories.node.frontmatter.films.map((el) => (
                <SlickItem href={el.link}>{el.name}</SlickItem>
              ))}
            </ReactSlickStyled>
          </CategoriesSlider>
        )}

        <FilmsWrapper>
          {serviceChannel === "youtube" &&
            !searchData &&
            !filteredData.length &&
            ytFilms &&
            ytFilms.map((el) => {
              return (
                <IframeWrapperYoutube>
                  <FilmTitle
                    dangerouslySetInnerHTML={{ __html: el.snippet.title }}
                  />
                  <IFrame
                    key={el.id.videoId}
                    src={`https://www.youtube.com/embed/${el.id.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  />
                </IframeWrapperYoutube>
              );
            })}

          {serviceChannel === "facebook" &&
            !searchData &&
            !filteredData.length &&
            fbFilms &&
            fbFilms?.map((el) => {
              return (
                <IframeWrapperFacebook>
                  <div style={{ position: "relative", width: "100%" }}>
                    <IFrameFb id={el.id} />
                  </div>
                </IframeWrapperFacebook>
              );
            })}
          {searchData !== "" &&
            !!filteredData.length &&
            filteredData.slice(0, perPage * page).map((el) => {
              return (
                <IframeWrapperYoutube>
                  <FilmTitle
                    dangerouslySetInnerHTML={{ __html: el.snippet.title }}
                  />
                  <IFrame
                    src={`https://www.youtube.com/embed/${el.id.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </IframeWrapperYoutube>
              );
            })}
          {((ytFilms.length >= 10 &&
            serviceChannel === "youtube" &&
            !searchData) ||
            (fbFilms.length >= 10 &&
              serviceChannel === "facebook" &&
              !searchData) ||
            filteredData.length >= 10) && (
            <NextButton onClick={() => setPage((prevState) => prevState + 1)}>
              Pobierz więcej wyników
            </NextButton>
          )}
        </FilmsWrapper>
        {searchData !== "" && filteredData.length === 0 && (
          <NoResults>Brak wyników</NoResults>
        )}
      </Section>
    </Layout>
  );
};

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Section = styled.div`
  z-index: 9999;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
`;

const NextButton = styled.button`
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: ${socialButtonsColors.default};
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  transition: 0.2s;
  margin: 30px auto 10px;
  ${mediaQuery.sm(`
    width: 100%;
  `)}

  &:hover, &:focus {
    outline: 0;
    cursor: pointer;
    opacity: 0.8;
  }
`;

const SearchModule = styled.div`
  display: flex;
  width: 100%;
`;

const FilmsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  width: 95%;
  margin: auto;
  margin-top: 0;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  & > div:first-of-type > span {
    margin-top: 0;
  }
  ${mediaQuery.sm(`
    width: 100%;
    margin-top: 0;
    padding-bottom: 0;
    justify-content: space-evenly;
          min-height: 50px;
      display: flex;
      align-items: flex-end;
    & > div:first-of-type > span {
      margin-top: 30px;
    }
  `)}
  ${mediaQuery.xl(`
  justify-content: space-around;
  `)}
`;

const CategoriesSlider = styled.div`
  display: none;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding-bottom: 10px;
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: ${colorsGlobal.primary};
  }
  
  .slick-next {
    right: 0;
  }
  .slick-slider {
    padding-right: 0;
  }
  ${mediaQuery.sm(`
    display: flex;
  `)}
`;

const CategoriesSliderTitle = styled.span`
  display: inline-flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`;

const ReactSlickStyled = styled(ReactSlick)`
  display: inline-block;
  width: 81%;
  position: relative;
  ${mediaQuery.sm(`
    width: 79%;
  `)}
  ${mediaQuery.lg(`
    width: 81%;
  `)}
`;

const SlickItem = styled.a.attrs({ target: "_blank" })`
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: black;
  &:hover {
    color: ${socialButtonsColors.youtube};
  }
`;

const SocialButtonStyled = styled(SocialButton)`
  display: inline-grid;
  align-items: center;
  width: 90px;
  padding: 5px 10px;
  flex-grow: 1;
  font-size: 12px;
  ${mediaQuery.sm(`
    font-size: 14px;
    height: 100%;
    line-height: 14px;
    padding: 8.5px 20px;
    width: auto;
  `)}
`;

const IframeWrapperYoutube = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 1;
  width: 100%;
  max-width: 450px;
  margin-bottom: 20px;
  ${mediaQuery.sm(`
    max-width: 450px;
  `)}
  @media only screen and (min-width: 1052px) {
    max-width: 500px;
  }
  @media only screen and (min-width: 1340px) {
    max-width: 550px;
  }
  @media only screen and (min-width: 1500px) {
    max-width: 650px;
  }
  @media only screen and (min-width: 1740px) {
    max-width: 700px;
  }
`;

const IframeWrapperFacebook = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
  max-height: 400px;
  margin-bottom: 6%;
  ${mediaQuery.sm(`
    width: 43%;
    margin-bottom: -13%;
    padding-bottom: 40%;
  `)}
`;

const IFrameStyled = styled.iframe`
  height: 100%;
  width: 100%;
`;

const FilmTitle = styled.span`
  font-size: 20px;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
  margin-bottom: 10px;
  margin-top: 30px;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
`;

const SearchComponent = styled.div`
  display: flex;
  flex-shrink: 1;
  width: 60%;
  order: 2;
  margin: 10px 0;
  margin-left: 20px;
  flex-grow: 1;
  ${mediaQuery.sm(`
    width: 50%;
      margin: 0;
      margin-left: 20px;
          order: 2;
    width: 50%;
  `)};
  ${mediaQuery.xl(`
    max-width: 600px;
    margin-right: 15px;
  `)}
`;

const SocialMediaSwitcher = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  max-width: 450px;
  order: 1;
  flex-grow: 1;
  position: relative;
  bottom: -15px;
  ${mediaQuery.xs(`
    order: 2;
  `)};
  ${mediaQuery.sm(`
    bottom: 0;
    margin: 0;
    justify-content: flex-end;
    margin-left: 20px;
    order: 3;
  `)}
  ${mediaQuery.xl(`
    margin-top: 0px;
    width: auto;
  `)}
`;

const SearchInput = styled.input`
  display: flex;
  padding: 9px 0 9px 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  ${mediaQuery.xl(`
    margin-top: 0px;
  `)}
`;

const NavTools = styled.div`
  position: relative;
  display: flex;
  width: 95%;
  z-index: 40;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 90px auto 30px;
  justify-content: space-between;
  max-height: 30px;
  padding-bottom: 75px;
  ${mediaQuery.xs(`
      justify-content: flex-start;
  `)};
  ${mediaQuery.sm(`
    padding: 0 2%;
    padding-bottom: 30px;
    margin: 0;
    align-items: center;
    width: 100%;
    padding-top: 130px;
    justify-content: space-between;
    flex-wrap: nowrap;
  `)};
  ${mediaQuery.xl(`
        flex-wrap: no-wrap;
  `)}
`;

const ChannelSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  order: 2;
  width: 21%;
  margin: 10px 0;
  ${mediaQuery.sm(`
  width: 35%;
      margin-top: 0;
      margin-right: 15px;
      margin-bottom: 0;
      order: 1;
  `)}

  ${mediaQuery.xl(`
      width: auto;
  `)}
`;

const SwitcherTitle = styled.div`
  display: none;
  flex-shrink: 0;
  align-items: center;
  margin-right: 15px;
  ${mediaQuery.sm(`
     display: flex;
  `)}
`;

const FirstLogo = styled.img`
  order: ${({ isFirstLogo }) => (isFirstLogo ? 2 : 3)};
  margin-right: ${({ isFirstLogo }) => (isFirstLogo ? 17 : 10)}px;
  height: ${({ isFirstLogo }) => (isFirstLogo ? 34 : 19)}px;
  width: ${({ isFirstLogo }) => (isFirstLogo ? 35 : 19)}px;

  &:hover {
    cursor: pointer;
    transform: scale(${({ isFirstLogo }) => (isFirstLogo ? 1 : 1.5)});
  }
`;

const SecondLogo = styled.img`
  order: ${({ isFirstLogo }) => (isFirstLogo ? 3 : 2)};
  margin-right: ${({ isFirstLogo }) => (isFirstLogo ? 10 : 17)}px;
  height: ${({ isFirstLogo }) => (isFirstLogo ? 19 : 34)}px;
  width: ${({ isFirstLogo }) => (isFirstLogo ? 19 : 35)}px;

  &:hover {
    cursor: pointer;
    transform: scale(${({ isFirstLogo }) => (isFirstLogo ? 1.5 : 1)});
  }
`;

export const filmsGraphqlQuery = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "films-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            films {
              link
              name
            }
            channelName
          }
        }
      }
    }
    wojnaIdeiFb {
      data {
        data {
          id
        }
        paging {
          cursors {
            after
            before
          }
        }
      }
    }
    wojnaIdeiYt {
      data {
        items {
          etag
          id {
            kind
            videoId
            channelId
            playlistId
          }
          kind
          snippet {
            channelId
            channelTitle
            description
            liveBroadcastContent
            publishTime
            publishedAt
            title
            thumbnails {
              default {
                height
                url
                width
              }
              high {
                height
                url
                width
              }
              medium {
                height
                url
                width
              }
            }
          }
        }
      }
    }
    szymonMowiYt {
      data {
        items {
          etag
          id {
            kind
            videoId
            channelId
            playlistId
          }
          kind
          snippet {
            channelId
            channelTitle
            description
            liveBroadcastContent
            publishTime
            publishedAt
            thumbnails {
              default {
                height
                url
                width
              }
              high {
                height
                url
                width
              }
              medium {
                height
                url
                width
              }
            }
            title
          }
        }
      }
    }
  }
`;
